




















































































































































































import Vue from "vue";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseWarehouseBranch } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  name: "WarehouseBranch",
  data() {
    return {
      dataListWarehouseBranch: {} as ResponseWarehouseBranch,
      trigerdisable: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "" as string,
      authPrivilege: [] as string[],
      typemodal: "" as string,
      loadingTable: false as boolean,
      direction: "" as string,
      titlemodalOpen: this.$t("lbl_warehouse_branch") as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "warehousebranch" }),
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      formRules: {
        code: {
          label: this.$t("lbl_branch_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        address: {
          label: this.$t("lbl_address"),
          name: "address",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "address",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_code"),
          dataIndex: "code",
          key: "code",
          sorter: true,
          width: "25%",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: "25%",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_address"),
          dataIndex: "address",
          key: "address",
          sorter: true,
          width: "25%",
          scopedSlots: { customRender: "isNull" },
          sortDirections: ["ascend", "descend", null],
        },
      ] as any,
    };
  },

  mounted() {
    this.getListOfWarehouseBranch("");
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "branch-warehouse") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: 120,
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfWarehouseBranch("");
    },
    reponseDeleteTable(response): void {
      const path = response.data.id;
      logisticServices.deleteWarehouseBranch(path).then(() => {
        this.ResetFilter(false);
        this.getListOfWarehouseBranch("");
      });
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_warehouse_branch").toString();
      this.dataDetail = "/" + response.data.id;
      this.ResetFilter(false);
      this.getListOfWarehouseBranch("/" + response.data.id);
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfWarehouseBranch("");
    },
    getListOfWarehouseBranch(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      logisticServices
        .listWarehouseBranch(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListWarehouseBranch = res;
          } else {
            const idtimeout = setTimeout(() => {
              this.form.setFieldsValue({
                code: res.code,
                name: res.name,
                address: res.address,
              });
              clearTimeout(idtimeout);
            }, 200);
            this.modalOpen = true;
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    reponseSearchInput(response): void {
      if (response) this.search = "name~*" + response + "*";
      else this.search = "";
      this.getListOfWarehouseBranch("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfWarehouseBranch("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfWarehouseBranch("");
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.form.resetFields();
      this.titlemodalOpen = this.$t(
        "lbl_create_new_warehouse_branch"
      ).toString();
      this.ResetFilter(false);
    },
    createNewWarehouseBranch(datapost): void {
      logisticServices
        .createWarehouseBranch(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfWarehouseBranch("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateWarehouseBranch(datapost): void {
      logisticServices
        .updateWarehouseBranch(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfWarehouseBranch("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e: Event, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datapost = {
            code: values.code,
            name: values.name,
            address: values.address,
          };
          this.isFormSubmitted = true;
          if (type === "create") {
            this.createNewWarehouseBranch(datapost);
          } else {
            this.updateWarehouseBranch(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
  },
});
